import { Box, Flex, BoxProps } from '@chakra-ui/core'
import Image from 'next/image'
import React, { FC } from 'react'

import heroImg from '../../../../../../public/img/homepage/MOBILE_Lumin_FT_HP2_1.jpeg'
import { useIsFreeTrialDisabled } from '../../../../freeTrial/hooks/useIsFreeTrialDisabled'
import { useRTL } from '../../../../i18n/hooks'
import HeroButtonDiscountedBox from '../HeroButtons/HeroButtonDiscountedBox'
import HeroButtonFreeTrial from '../HeroButtons/HeroButtonFreeTrial'
import HeroButtonProducts from '../HeroButtons/HeroButtonProducts'
import HeroDesc from '../HeroDescOriginal'

import {
  HOME_PAGE_HERO_BACKGROUND_CONFIG,
  HOME_PAGE_HERO_LAYOUT_TYPE,
} from 'modules/homePage/types'
import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'
import { genericBlurDataUri } from 'modules/shared/constants/general'
import { BusinessModel } from 'modules/shared/types'

const SplitHeroMobileView: FC<BoxProps> = ({ display }) => {
  const { isRTL } = useRTL()
  const isFreeTrialDisabled = useIsFreeTrialDisabled()

  const homePageHeroLayoutType = useFeatureFlag(
    'HOME_PAGE_HERO_LAYOUT_TYPE',
    HOME_PAGE_HERO_LAYOUT_TYPE.SPLIT_IMAGES
  )

  const optlyOverrideHeroBackground: HOME_PAGE_HERO_BACKGROUND_CONFIG =
    useFeatureFlag('HOME_PAGE_HERO_BACKGROUND', {})

  const heroImage = optlyOverrideHeroBackground?.images?.desktop?.[0] || heroImg

  return (
    <>
      <Box minH="370px" position="relative" display={display}>
        <Box
          position="relative"
          h="370px"
          w="100%"
          zIndex="hide"
          transform={isRTL ? 'scale(-1, 1)' : 'initial'}
        >
          {homePageHeroLayoutType === HOME_PAGE_HERO_LAYOUT_TYPE.VIDEO && (
            <Box
              pt="70px"
              as="video"
              poster="/img/halloween-hero-poster.png"
              src="https://cdn.shopify.com/videos/c/o/v/ab4dca0e58524efb810d28eefa477918.mp4"
              autoPlay
              playsInline
              muted
              controls={false}
              objectFit="fill"
              h="370px"
              loop
            />
          )}

          {homePageHeroLayoutType !== HOME_PAGE_HERO_LAYOUT_TYPE.VIDEO && (
            <Image
              src={heroImage}
              alt="hero"
              objectFit="cover"
              layout="fill"
              placeholder="blur"
              blurDataURL={genericBlurDataUri}
              priority
            />
          )}
          {optlyOverrideHeroBackground.showOverlay && (
            <Box
              top={0}
              w="100%"
              h="100%"
              pos="absolute"
              bg="black"
              opacity="0.6"
            />
          )}
        </Box>
        <HeroDesc zIndex="0" px={3} />
      </Box>
      <Flex
        direction="column"
        align="center"
        justify="center"
        bgColor="sage"
        display={display}
        py={10}
        px={4}
      >
        {isFreeTrialDisabled === false && <HeroButtonFreeTrial />}
        <HeroButtonProducts />
      </Flex>
    </>
  )
}

export default SplitHeroMobileView
