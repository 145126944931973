import { Text, Box, Flex } from '@chakra-ui/core'
import { Product } from '@pangaea-holdings/pangaea-checkout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import { formatCurrencyInteger } from '../../../../utils/currency'
import { getOneTimePurchasePrice } from '../../../products/functions'
import productRoutes from '../../../products/routes'
import ShopifyNextLink from '../ShopifyNextLink'

interface FeaturedProductCardProps {
  product: Product
  sizeId?: number
}

const FeaturedProductCard: React.FC<FeaturedProductCardProps> = ({
  product,
}) => {
  const { t } = useTranslation()

  const price = getOneTimePurchasePrice(product)

  if (!price) {
    return null
  }

  return (
    <Flex
      textAlign="center"
      py={3}
      px={[4, 5, 10]}
      maxW="270px"
      w={[1 / 2, 1 / 2, 1 / 4]}
      align="flex-start"
    >
      <ShopifyNextLink
        href={productRoutes.detail()}
        as={productRoutes.detail({
          slug: product.slug,
        })}
        height="100%"
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Box
            position="relative"
            h={['80px', null, '130px']}
            w={['80px', null, '130px']}
            mb={8}
            px={5}
          >
            <Image
              alt={t(`products:${product.slug}.name`, product.title)}
              src={product.primaryImageUrl}
              placeholder="blur"
              blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8Ww8AAh8BTjL/F68AAAAASUVORK5CYII="
              layout="fill"
            />
          </Box>
          <Text
            as="h3"
            size="md"
            mb={2}
            maxW={['128px', '128px', '190px']}
            whiteSpace="normal"
            fontWeight="bold"
            flex={1}
          >
            {t(`products:${product.slug}.name`, product.title)}
          </Text>

          <Text size="md">
            {formatCurrencyInteger(price, product.currency)}
          </Text>
        </Flex>
      </ShopifyNextLink>
    </Flex>
  )
}

export default FeaturedProductCard
