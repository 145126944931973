import { Flex, Box, Text, Heading } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import ingredientsRoutes from '../../ingredients/routes'
import ShopifyNextLink from '../../shared/components/ShopifyNextLink'

const LittleBackgroundSection = () => {
  const { t } = useTranslation()

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      textAlign="center"
      pt={12}
      px={5}
      backgroundColor="moss"
      color="whiteSands"
    >
      <Flex
        direction="column"
        justifyContent="center"
        maxW="1000px"
        zIndex={1}
        mt={3}
      >
        <Text size="sm" mb={[3, 3, 4]} fontWeight="bold">
          {t('home:little-background-header', 'A LITTLE BACKGROUND')}
        </Text>
        <Heading
          as="h2"
          fontSize={['1.625rem', null, '3.125rem']}
          lineHeight={['2rem', null, '3.875rem']}
          letterSpacing={['0.16px', null, 0]}
          mb={[4, 4, 6]}
        >
          {t(
            'home:little-background-details',
            'We sought out the highest quality, most effective ingredients. We traveled countless miles to consult skincare experts in Seoul. We did it all for the greater good of man (and his skin).'
          )}
        </Heading>
        <ShopifyNextLink
          href={ingredientsRoutes.ingredients()}
          textDecoration="underline"
        >
          {t('home:learn-about-our-ingredients', 'Learn about our ingredients')}
        </ShopifyNextLink>
      </Flex>
      <Flex
        maxW="689px"
        justify="center"
        align="center"
        mt={['-50px', '-100px', '-100px']}
        position="relative"
        width={[1, '689px']}
      >
        <Box width="100%" h={['297px', '532px']} position="relative">
          <Image
            src="https://cdn.shopify.com/s/files/1/0044/1237/5107/files/Home_WhyLuminSilhouette.png?v=1586895019"
            alt="why-men-love-lumin"
            layout="fill"
            sizes="60vw"
          />
        </Box>
      </Flex>
    </Flex>
  )
}
export default LittleBackgroundSection
