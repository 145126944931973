import { Box } from '@chakra-ui/core'
import Image from 'next/image'
import React from 'react'

import defaultHeroImg from '../../../../../../public/img/homepage/Lumin_Hero_HP_1.jpeg'

import { useIsMobileWidth } from 'design/hooks/useWindowDimensions'

const HeroLeftSection = ({ heroImage, fullWidth = false }) => {
  const isMobile = useIsMobileWidth()

  const bgImage = heroImage || defaultHeroImg

  return (
    <Box minH="750px" w={fullWidth ? '100%' : '50%'} position="relative">
      <Box
        display={['none', 'flex']}
        width="100%"
        height="750px"
        position="relative"
        zIndex="hide"
        bg="moss"
      >
        <Image
          alt="classic maintenance collection"
          src={bgImage}
          layout="fill"
          objectFit="cover"
          priority={!isMobile}
          sizes="70vw"
        />
      </Box>
    </Box>
  )
}

export default HeroLeftSection
