import React from 'react'
import { useTranslation } from 'react-i18next'

import productRoutes from '../../products/routes'
import ShopSection from './ShopSection'

const ShopSkinSection = () => {
  const { t } = useTranslation()
  return (
    <ShopSection
      header={t('home:face', 'Face')}
      desc={t(
        'home:skin-section-desc',
        'Dry skin, face fatigue, fine lines, and wrinkles. We’ve got a thing or two for that.'
      )}
      buttonTitle={t('common:shop-face', 'Shop Face')}
      buttonUrl={productRoutes.products({ category: 'face' })}
      imgUrl="https://cdn.shopify.com/s/files/1/0044/1237/5107/files/Studio_Day_41331.jpg?v=1586986863"
      placeHolderUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsqyz9DwAFNgJlrN0zWAAAAABJRU5ErkJggg=="
      direction="column-reverse"
    />
  )
}
export default ShopSkinSection
