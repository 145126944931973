import React from 'react'
import { useTranslation } from 'react-i18next'

import productRoutes from '../../products/routes'
import ShopSection from './ShopSection'

const ShopHairAndBodySection = () => {
  const { t } = useTranslation()
  return (
    <ShopSection
      header={t('home:hair-and-body', 'Hair & Body')}
      desc={t(
        'home:hair-and-body-desc',
        'Fortify your hair follicles, give thinning areas some volume, and treat your body’s skin like driving your dream car off the lot.'
      )}
      buttonTitle={t(
        'home:hair-and-body-section-button-title',
        'Shop Hair & Body'
      )}
      buttonUrl={productRoutes.products({ category: 'hair-and-body' })}
      imgUrl="https://cdn.shopify.com/s/files/1/0044/1237/5107/files/Studio_Day_41757.jpg?v=1586986862"
      placeHolderUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsqyz9DwAFNgJlrN0zWAAAAABJRU5ErkJggg=="
      direction="column-reverse"
      rowDirection="row-reverse"
    />
  )
}
export default ShopHairAndBodySection
