import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { trackHomeStartFreeTrialButtonClick } from '../../../events'
import HeroButtonSection from './HeroButtonSection'

const HeroButtonFreeTrial = () => {
  const { t } = useTranslation()
  const { push } = useRouter()

  const handleStartFreeTrial = async () => {
    const quizPath = '/pages/why-men-love-lumin'
    trackHomeStartFreeTrialButtonClick(quizPath)
    push({
      pathname: quizPath,
      query: { startQuiz: 'true' },
    })
  }

  return (
    <HeroButtonSection
      buttonVariant="primary"
      buttonTitle={t('common:start-free-trial', 'Start Free Trial')}
      desc={t(
        'home:hero-section.start-free-trial-desc',
        'Try any of our skincare routine sets for free. See results in just 30 days.'
      )}
      className="free-trial-hero"
      onClick={handleStartFreeTrial}
    />
  )
}

export default HeroButtonFreeTrial
